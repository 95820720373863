import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PAGE_TITLE = "Future Projects"
const PAGE_NAME = "projects"
const ProjectsPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <p>
          <br /><br /><br />
		  <h1>***PAGE UNDER CONSTRUCTION***</h1>
		  <br /><br /><br /><br />
        </p>
		
		<div className="row">
          <div className="col-sm-12">

			<p>
				AJVCD is involved in a large range of different projects. Below are listed some of the future projects that we are focussing on for future work. If you would like to get involved, please get in touch.
				<br /><br />
			</p>

            <ul>
			  <li>
				<b>Reforestation project </b>: We continue our pursuit to achieve our goal of planting 1 Million trees in the region by 2021, through producing agroforestry seedlings and fruits trees that also help fight against land degradation and erosion, and native trees to help improve biodiversity in Virunga National Park. 
				<br /><br />
			  </li>
			  <li>
				<b>School gardens project </b>: AJVDC would like to work with the education system to start local gardens at schools, and to start training students in farming (vegetables, rabbit, chickens) for sustinence, and for selling through agribusiness program (markets/shops etc). Umuntu Movement is willing to train up to 5000 students in school year period, 2020-2021.
				<br /><br />
			  </li>
			  <li>
				<b>Kitchen gardens project </b>: We would like to help those from vulnerable communitiesin the slums of Goma and rural areas, with the aim of training them how to grow food in their homes, in small spaces, in bags or boxes in their homes. The goal here is to provide a multifaceted approach to fighting food insecurity in this COVID-19 period, and in general.
				<br /><br />
			  </li>
			  <li>
				<b>Micro–lending project </b>: We would like to develop a Micro–lending program working with smallholders, farmers and (in particular) female small business owners to build their financial capacity and management capacity. 
				<br /><br />
			  </li>
			  <li>
				<b>Water project: </b> AJVDC would like to provide clean water to more than 6000 people or 50 households per day from poor slum of Goma city, by installing a mobile water tank of 10m3.
				<br /><br />
			  </li>
			  <li>
				<b>Fish farming project </b>: We plan to install fish farming site with floating cages for Tilapia and Catfish. Installing just 10 cages in one site, could produce 200,000 fish per harvest (7 months) = 150 tonnes. This would feed many hungry people provide good steady work for youth and women. 
				<br /><br />
			  </li>
			</ul>
          </div>
        </div>
        
      </section>
    </Layout>
  )
}

export default ProjectsPage
